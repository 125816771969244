<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" @showButton="showButtonO" ref="childTab1" />
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true">
        <childTab2 :isShow="isSho" @draftState="draftState" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'" :lazy="true">
        <childTab3 :showButton="showButton" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MdinEditMain from '@/views/MouldManagement/MdinManage/TabChild/MdinEditMain';
import MdinEditAppr from '@/views/MouldManagement/MdinManage/TabChild/MdinEditAppr';
import MdinEditAbout from '@/views/MouldManagement/MdinManage/TabChild/MdinEditAbout';
export default {
  name: 'AcctEdit',
  components: {
    childTab1: MdinEditMain,
    childTab2: MdinEditAppr,
    childTab3: MdinEditAbout
  },
  data() {
    return {
      activeName: 'first',
      isSho: true,
      showButton: {}
    };
  },
  created() {},
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    showButtonO(val) {
      this.showButton = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    },
    handleClick(tab, event) {
      // console.log('val',this.activeName);
    }
  }
};
</script>

<style scoped lang="scss"></style>
